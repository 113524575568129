export const migrationCodeTpl = `use Illuminate\\Support\\Facades\\Schema;
use Illuminate\\Database\\Schema\\Blueprint;
use Illuminate\\Database\\Migrations\\Migration;

class %CLASSNAME% extends Migration
{
      /**
       * Run the migrations.
       *
       * @return void
       */
      public function up()
      {
          Schema::%MODE%('%TABLE%', function (Blueprint $table) {
%UPCODE%
          });
      }

      /**
       * Reverse the migrations.
       *
       * @return void
       */
      public function down()
      {
%DOWNCODE%
      }
  }`

export const placeholderCode = `/*
Help:
1- Enter table name.
2- Add some fields.
3- Click [Generate].
*/`
