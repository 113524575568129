<template>
  <tr>
    <td>
      <template v-if="selectedType.noname">
        {{ selectedType.label }}
      </template>
      <template v-else>
        <input type="text" v-model="value.title" class="form-control" required />
      </template>
    </td>
    <td>
      <select @change="typeIdUpdated" v-model="selectedTypeId" class="field-type form-select" required>
        <option value="" disabled>Select Type...</option>
        <option
        v-for="(type, index) in columnTypes"
        :value="type.key"
        :key="index"
        >{{ type.label }}</option>
      </select>
      <template v-if="selectedParams > 0">
        <div class="input-group mt-1">
          <input type="text" v-for="n in selectedParams" class="form-control"
          :value="vars[n-1].v"
          @input="setTypeVars($event, n-1)"
          :key="n"
          :placeholder="selectedType.defaults[n-1]"
          />
        </div>
      </template>
    </td>
    <td>
      <span v-show="selectedType.tip" class="hint--right  hint--medium" :aria-label="selectedType.tip">
        <i class="bi bi-question-circle"></i>
      </span>
    </td>
    <td>
      <a href="#" @click.prevent="remove()" class="text-danger"><i class="bi bi-dash-square fs-5"></i></a>
    </td>
    <td>
      <button type="button" class="btn btn-xs btn-default" v-show="myKey > 0" @click="reorder(-1)"><i class="bi bi-arrow-up fs-5"></i></button>
      <button type="button" class="btn btn-xs btn-default" v-show="myKey < rowsLength - 1" @click="reorder(1)"><i class="bi bi-arrow-down fs-5"></i></button>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'pinia'
import { useMigrationsStore } from '../store/migrations'

export default {
  name : 'TableColumn',
  props: {
    modelValue: Object,
    myKey: Number,
    rowsLength: Number
  },
  emits: ['update:modelValue'],
  data() {
    return {
      vars: [],
      selectedTypeId: '',
      selectedType: {},
      selectedParams: 0
    }
  },
  created() {
    if (this.value.type) {
      this.selectedType = this.value.type
      this.selectedTypeId = this.value.type.key
      this.typeIdUpdated()
    }
  },
  computed: {
    ...mapState(useMigrationsStore, { columnTypes: "colTypes" }),
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    typeIdUpdated() {
      let  i
      this.selectedType = this.columnTypes.find(item => item.key === this.selectedTypeId)
      this.selectedParams = this.selectedType.params
      this.value.type = this.selectedType
      if (this.selectedType.params) {
        this.vars = []
        for (i in this.selectedType.defaults) {
          this.vars.push({v: this.selectedType.defaults[i]})
        }
      }
      this.value.vars = this.vars
    },
    setTypeVars(event, index) {
      this.vars[index].v = event.target.value
      this.value.vars = this.vars
    },

    reorder(increment) {
      this.$emit('field-ordered', this.myKey, increment)
    },

    remove() {
      this.$emit('field-remove', this.myKey)
    }
  }
}
</script>
