<template>
  <div class="modal" id="changelogModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Changelog</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <dl class="row">
                <dt class="col-sm-3">1.3</dt>
                <dd class="col-sm-9"><i class="bi bi-calendar-event"></i> 2023-09-10</dd>

                <dt class="col-sm-3"><span class="badge bg-success">Added</span></dt>
                <dd class="col-sm-9">Laravel Version 10.x</dd>
            </dl>

            <dl class="row">
                <dt class="col-sm-3">1.2</dt>
                <dd class="col-sm-9"><i class="bi bi-calendar-event"></i> 2022-08-27</dd>

                <dt class="col-sm-3"><span class="badge bg-success">Added</span></dt>
                <dd class="col-sm-9">Laravel Version 9.x</dd>
            </dl>

            <dl class="row">
                <dt class="col-sm-3">1.1</dt>
                <dd class="col-sm-9"><i class="bi bi-calendar-event"></i> 2022-07-28</dd>

                <dt class="col-sm-3"><span class="badge bg-success">Added</span></dt>
                <dd class="col-sm-9">Laravel Version 8.x</dd>
            </dl>

            <dl class="row">
                <dt class="col-sm-3">1.0</dt>
                <dd class="col-sm-9"><i class="bi bi-calendar-event"></i> 2020-09-08</dd>

                <dt class="col-sm-3">&nbsp;</dt>
                <dd class="col-sm-9">First Release</dd>
            </dl>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
  export default {
    name: 'MigrationBuilderChangelog',
    data() {
      return {}
    }
  }
</script>
<style scoped>
dt { text-align: right;}
</style>