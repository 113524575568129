<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from './layout/Sidebar.vue'

export default {
  name: 'App',
  components: {
    Sidebar
  }
}
</script>

<style>
@import '../node_modules/hint.css/hint.min.css';
@import './assets/styles/app.css';
</style>
