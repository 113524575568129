import { defineStore } from 'pinia'
import axios from 'axios'

export const useMigrationsStore = defineStore('migrations', {
    state: () => ({
        versions: ['10.x', '9.x', '8.x', '7.x', '5.6'],
        version: '10.x',
        colTypes: [],
        isLoading: false
    }),
    // getters: {
    //     doubleCount: (state) => state.count * 2,
    // },
    actions: {
        loadColTypes() {//api/data/migrations/schema_7.x.json
            return new Promise((resolve, reject) => {
                this.isLoading = true
                axios.get('api/data/migrations/schema_' + this.version + '.json')
                    .then(resp => {
                        let result = resp.data
                        this.colTypes = result.column_types
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            })
        },
    
        setVersion(version) {
            this.version = version
            this.loadColTypes()
        }
    },
})