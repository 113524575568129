<template>
<nav class="bg-white col-md-3 col-lg-2 d-md-block sidebar-sticky">
  <div class="d-flex flex-column flex-shrink-0 p-3" style="width: 280px;">
    <router-link :to="{path: '/'}" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
        <img id="site-logo" class="d-inline-block align-top mr-1" src="../assets/wbly.png" alt="wbly">
        <span class="d-none d-md-inline ml-1 logo">wb.ly</span>
        <sub class="slogan">Web Tools</sub>
    </router-link>
    
    <hr>
    <ul class="nav nav-pills flex-column mb-auto">
    <li class="nav-item">
        <router-link :to="{path: '/'}" class="nav-link">
            <i class="bi bi-server"></i>
            Laravel Database Migration
        </router-link>
    </li>
    
    </ul>
    <hr>
    <div class="dropdown">
        
    <a href="#" class="d-flex align-items-center link-dark text-decoration-none dropdown-toggle" id="dropdownLinks" data-bs-toggle="dropdown" aria-expanded="false">
        <small>&copy; {{ copyRightYear }}</small> &nbsp; <img src="https://www.gravatar.com/avatar/009532c9a99dc501afd966ade733c9a0" alt="" width="32" height="32" class="rounded-circle me-2">
        <span>Wesam Alalem</span>
    </a>
    <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownLinks">
        <li><a class="dropdown-item" href="https://wesam.ly"><i class="bi bi-globe"></i> Website</a></li>
        <li><a class="dropdown-item" href="https://twitter.com/wesamly"><i class="bi bi-twitter"></i> Twitter</a></li>
        <li><a class="dropdown-item" href="https://www.linkedin.com/in/wesamalalem/"><i class="bi bi-linkedin"></i> LinkedIn</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="https://wesam.ly/contacts/"><i class="bi bi-chat-left-text"></i> Contact</a></li>
    </ul>
    </div>
</div>
</nav>
</template>
<script>
  export default {
    name: 'AppSidebar',
    data() {
      return {
        copyRightYear: 2020
      }
    },
    mounted() {
        this.copyRightYear = (new Date).getFullYear()
    }
  }
</script>
<style scoped>
.nav-link {
    padding-right: 0;
    padding-left: 0;
}
#site-logo {
    padding-right: 0.25em;
}
span.logo {
    font-family: 'Exo', sans-serif;
    font-size: 2.5em;
    font-weight: bold;
    color: #E15119;
    padding-right: 0.25em;
}
</style>