import { createRouter, createWebHashHistory } from "vue-router"

import LaravelMigrationBuilder from '@/modules/laravel_migration/views/Builder.vue'
import NotFound from '@/components/NotFound.vue'

const routes = [
    { 
        path: '/:pathMatch(.*)*',
        name: 'NotFound', 
        component: NotFound
    },
    {
        path: '/',
        component: LaravelMigrationBuilder
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

export default router