<template>
  <div class="laravel_migrations">
    <div class="page-header row no-gutters py-4">
      <div class="col-12 text-center text-sm-left mb-0">
        <h3 class="page-title">Laravel Database Migration Builder</h3>
        <span
          class="text-uppercase2 page-subtitle"
        >Use this tool to create a one table migration for Laravel</span>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <div class="card card-small mb-4">
          
          <div class="card-body">
            <form action method="post" @submit.prevent="generateMigration" id="migrateForm">

              <div class="row">
                <div class="col">
                  <input type="text" v-model="tableName" class="form-control" id="tablename" placeholder="Table Name" required />
                </div>
                <div class="col">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="createMode" value="create" v-model="migrateMode" />
                    <label class="form-check-label" for="createMode">Create</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" id="modifyMode" value="table" v-model="migrateMode" />
                    <label class="form-check-label" for="modifyMode">Modify</label>
                  </div>
                </div>
              </div>

              <fieldset class="mt-2">
                <strong class="text-muted d-block mb-2">Fields</strong>
                <table class="table mb-0 table-bordered table-sm fields-list">
                  <thead class="bg-light">
                    <tr>
                      <th scope="col">Column</th>
                      <th scope="col">Type</th>
                      <th scope="col">&nbsp;</th>
                      <th scope="col">&nbsp;</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <field
                      v-for="(field, index) in fields" :key="field.id"
                      :my-key="index"
                      v-model="fields[index]"
                      :rows-length="fields.length"
                      @type-updated="fieldTypeUpdate"
                      @field-ordered="fieldReordered"
                      @field-remove="fieldRemove"
                    ></field>

                    <tr v-if="fields.length == 0">
                      <td colspan="5">Add field below.</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>

              <input type="submit" id="migrateFormSubmit" style="display: none" value="Generate" />
            </form>

            <div class="row mt-2">
              <div class="col-4">
                <select v-model="newFieldOption" class="form-select">
                  <option value="text">Add field</option>
                  <optgroup label="Custom Fields">
                      <option v-for="item in noNameFields" :value="item.key" :key="item.key">{{ item.label }}</option>
                  </optgroup>
                </select>
                </div>
                <div class="col-4">
                  <input type="text" class="form-control" v-model="newFieldText" placeholder="Type field name + Enter" @keyup.enter="addField()" v-show="newFieldOption === 'text'" />
                  <a href="#" class="text-success" @click.prevent="addCustomField()" v-show="newFieldOption !== 'text'">
                    <i class="bi bi-plus-square fs-5"></i>
                  </a>
                </div>
            </div>

            <hr />

            <button type="button" class="btn btn-primary" @click="submitForm()"><i class="bi bi-code fs-6"></i> Generate</button>

            <div>
              <br />
              <div class="row" v-show="generatedCode !== ''">
                <div class="col text-end">
                  <div class="btn-group float-right2" role="group" aria-label="Copy/Export">
                    <button type="button" class="btn btn-sm btn-outline-light ripple" @click="copyToClipboard()">
                      <i class="bi bi-clipboard-plus"></i> Copy
                    </button>
                    <button type="button" class="btn btn-sm btn-outline-light" @click="downloadFile()">
                      <i class="bi bi-file-earmark-arrow-down-fill"></i> Download
                    </button>
                  </div>
                </div>
              </div>

              <pre><code class="language-php line-numbers" id="code">/*
Help:
1- Enter table name.
2- Add some fields.
3- Click [Generate].
*/</code></pre>

            </div>
          </div>
        </div>
      </div>
      <div class="col-3">
        <!-- Sidebar -->
        <div class="card card-small mb-3">
          <div class="card-header border-bottom">
            Actions
          </div>
          <div class="card-body p-0">
            <ul class="list-group list-group-flush">
              <li class="list-group-item d-flex px-3">
                <label class="sel-version-label">Laravel Version</label>
                <select class="form-select sel-version-control" @change="setLaravelVersion" v-model="laravelVersion">
                  <option :value="item" v-for="(item, index) in versions" :key="index">{{ item }}</option>
                </select>
              </li>
              <li class="list-group-item d-flex px-3">
                <button class="btn btn-sm btn-outline-secondary" @click="resetInputs">
                <i class="bi bi-arrow-clockwise"></i> Reset</button>
              </li>
            </ul>
          </div>
        </div>

        <div class="card card-small mb-3">
          <div class="card-body p-033">
            App Version: 1.3 (<a href="#" @click.prevent="showChangelog">Changelog</a>)
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col">
          <!-- FOOTER -->
      </div>
    </div>
    <changelog></changelog>
  </div>
</template>

<script>

import { mapState, mapActions } from "pinia"
import Prism from 'prismjs'
import "prismjs/components/prism-php";
import "prismjs/themes/prism.css"
import "prismjs/themes/prism-okaidia.css"
import 'prismjs/components/prism-markup-templating'
import { saveAs } from 'file-saver'
import { Modal } from 'bootstrap'
import { useMigrationsStore } from '../store/migrations'
import Field from "../components/Field.vue"
import Changelog from "../components/Changelog.vue"
import {migrationCodeTpl, placeholderCode} from '../code_template'

export default {
  name: "MigrationBuilder",
  components: { Field, Changelog },
  metaInfo: {
    title: 'Laravel Database Migration Builder - wb.ly Web Tools',
    description: 'Build Laravel database migration with this simple tool'
  },
  data() {
    return {
      newFieldText: "",
      nextFieldId: 0,
      fields: [],
      tableName: "",
      generatedCode: "",
      newFieldOption: "text",
      migrateMode: "create",
      laravelVersion: ''
    };
  },
  created() {
    this.loadColTypes()

    document.title = this.$options.metaInfo.title
    const descEl = document.querySelector('head meta[name="description"]');
    descEl.setAttribute('content', this.$options.metaInfo.description)
  },
  mounted() {
    this.laravelVersion = this.version
  },
  computed: {
    ...mapState(useMigrationsStore, { columnTypes: "colTypes", versions: "versions", version: "version" }),
    noNameFields() {
      return this.columnTypes.filter(function (item) {
        return item.noname
      });
    },
  },
  watch: {
    generatedCode(val) {
      let codeElm;
      //Highlight code
      codeElm = document.getElementById("code")
      //FIX: why not working with Reactive variable!
      if (val !== '') {
        codeElm.innerHTML = val
      } else {
        codeElm.innerHTML = placeholderCode
      }
      
      Prism.highlightElement(codeElm)
      
    },
  },
  methods: {
    ...mapActions(useMigrationsStore, ['loadColTypes', 'setVersion']),

    addField() {
      this.fields.push({ id: this.nextFieldId, title: this.newFieldText, type: '' })
      this.newFieldText = ""
      this.nextFieldId++
    },

    addCustomField() {
      let newFieldType;
      //Find field type by selected field option
      newFieldType = this.noNameFields.find(function (item) {
        return item.key == this.newFieldOption
      }, this);

      this.fields.push({
        id: this.nextFieldId,
        title: "field_" + this.nextFieldId,
        type: newFieldType,
      });
      this.nextFieldId++
    },

    fieldTypeUpdate(key, data) {
      this.fields[key] = data;
    },

    submitForm() { 
        document.getElementById("migrateFormSubmit").click()
    },

    generateMigration() {
        
      let template, className, upCode, i, field, downCode, addedFields
      template = migrationCodeTpl

      className = this.migrateMode == "create" ? "Create" : "Modify"
      className += this.snake2Pascal(this.tableName)
      className += "Table"

      template = template.replace("%CLASSNAME%", className)
      template = template.replace(new RegExp("%TABLE%", "g"), this.tableName)
      template = template.replace("%MODE%", this.migrateMode)
      addedFields = []
      upCode = ""
      for (i in this.fields) {
        field = this.fields[i]
        upCode += "\t\t\t" + "$table->" + field.type.key + "("
        if (!field.type.noname) {
          upCode += "'" + field.title + "'"
          addedFields.push(field.title)
        }

        if (field.type.params > 0) {
          for (let j = 0; j < field.type.params; j++) {
            if (field.vars[j]) {
              upCode += !field.type.noname ? ', ' : ''
              upCode += field.vars[j].v
            }
          }
        }
        upCode += ");" + "\n"
      }
      template = template.replace("%UPCODE%", upCode)

      downCode = ""
      if (this.migrateMode == "table") {
        downCode =
          "\t\tSchema::table('" +
          this.tableName +
          "', function (Blueprint $table) {\n"
        downCode +=
          "\t\t\t$table->dropColumn(['" + addedFields.join("','") + "']);\n"
        downCode += "\t\t});";
      } else {
        downCode = "\t\tSchema::dropIfExists('" + this.tableName + "');"
      }
      template = template.replace("%DOWNCODE%", downCode)
      this.generatedCode = template
    
      return false
    },

    /**
     * Reorder fields
     *
     * @param  integer key       Field key
     * @param  integer increment 1/-1 (up/down)
     * @return void
     */
    fieldReordered(key, increment) {
      let temp, newIndex
      newIndex = key + increment * 1
      temp = this.fields[key]
      this.fields[key] = this.fields[newIndex]
      this.fields[newIndex] = temp
      
    },

    fieldRemove(key) {
      this.fields.splice(key, 1)
    },

    copyToClipboard() {
      let textArea

      textArea = document.createElement("textarea")

      textArea.style.position = "fixed"
      textArea.style.top = 0
      textArea.style.left = 0
      textArea.style.width = "2em"
      textArea.style.height = "2em"
      textArea.style.padding = 0
      textArea.style.border = "none"
      textArea.style.outline = "none"
      textArea.style.boxShadow = "none"
      textArea.style.background = "transparent"
      textArea.value = this.generatedCode
      document.body.appendChild(textArea)
      textArea.select()
      try {
        document.execCommand("copy")
      } catch (err) {
        console.log("Error: unable to copy!")
      }
      document.body.removeChild(textArea)
    },

    getCurrentDateText() {
      let currentDate, year, month, day, hour, minute, second

      currentDate = new Date()
      year = currentDate.getFullYear()
      month = String(currentDate.getMonth() + 1).padStart(2, '0')
      day = String(currentDate.getDate()).padStart(2, '0')
      hour = String(currentDate.getHours()).padStart(2, '0')
      minute = String(currentDate.getMinutes()).padStart(2, '0')
      second = String(currentDate.getSeconds()).padStart(2, '0')

      return year + "_" + month + "_" + day + "_" + hour + minute + second
    },

    downloadFile() {
      let mimeType, code, filename

      mimeType = "text/x-php;charset=utf-8"
      code = '<?php' + "\n\n" + this.generatedCode

      filename = this.getCurrentDateText()
      filename += this.migrateMode == 'create' ? '_create' : '_modify'
      filename += '_' + this.tableName + '_table'
      filename += '.php'

      try {
        let blob = new Blob([code], {type: mimeType})
        saveAs(blob, filename)
      } catch (e) {
        window.open("data:" + mimeType + "," + encodeURIComponent(this.generatedCode), '_blank' , '')
      }
    },

    snake2Pascal(str) {
        str +=''
        str = str.split('_')

        function upper( str ){
            return str.slice(0,1).toUpperCase() + str.slice(1,str.length)
        }

        for (let i = 0; i < str.length; i++) {
            let str2 = str[i].split('/')
            for (let j = 0; j < str2.length; j++) {
                str2[j] = upper(str2[j])
            }
            str[i] = str2.join('')
        }
        return str.join('')
    },

    resetInputs() {
        this.fields = []
        this.tableName = ""
        this.generatedCode = ''
        let codeElm = document.getElementById("code")
        codeElm.innerHTML = placeholderCode
        
    },
    
    setLaravelVersion() {
      this.setVersion(this.laravelVersion)
    },

    showChangelog() {
      let modal = new Modal(document.getElementById('changelogModal'))
      modal.show()
    }
  },
};
</script>

<style scoped>
.laravel_migrations {
    background-color: #f5f6f8;
}
.card-body {padding-top: 10px !important;}
table.fields-list tr th:last-child {
    width: 15%;
}
.ripple{position:relative;overflow:hidden;transform:translate3d(0,0,0)}
.ripple:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;pointer-events:none;background-image:radial-gradient(circle,#000 10%,transparent 10.01%);background-repeat:no-repeat;background-position:50%;transform:scale(10,10);opacity:0;transition:transform .5s,opacity 1s}
.ripple:active:after{transform:scale(0,0);opacity:.2;transition:0s}

.sel-version-label {width: 50%;}
.sel-version-control {width: 50%;}
.btn-outline-light {
    color: #212529;
    border-color: #212529;
}
</style>
