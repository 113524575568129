import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'

import App from './App.vue'
import router from './router'

const pinia = createPinia()

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

createApp(App).use(router).use(pinia).mount('#app')
